import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
const _ = require("lodash")

const Post = ({ post }) => {
  return (
    <div className="post">
      <div className="images">
        {post.frontmatter.images.map(image => {
          return <Img className="image" fluid={image.childImageSharp.fluid} />
        })}
      </div>
      <div className="date">{post.frontmatter.date}</div>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <ul className="tags">
        {post.frontmatter.tags.map((tag, i) => {
          return (
            <li key={i} className="tag">
              #<Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Post
