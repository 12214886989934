import React from "react"
import Layout from "../components/Layout"
import Post from "../components/Post"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

const BlogPostTemplate = ({ data }) => {
  const { markdownRemark: post } = data
  const image = post.frontmatter.images[0].childImageSharp.original.src
  return (
    <Layout>
      <SEO
        description={post.excerpt}
        keywords={post.frontmatter.tags}
        image={image}
      />
      <div className="blog-post">
        <Post post={post} />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...PostNode
    }
  }
`
